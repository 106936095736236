<template>
	<a-modal :width="390" :visible="payModalVisible" title="扫码付款" class="payModal" @cancel="closeModal" :footer="false">
		<a-tabs :tabBarGutter="130" @change="changeTabsKey" :activeKey="activeKey" class="payBox">
			<a-tab-pane key="wechatPay" tab="微信支付" class="payText">
				<a-spin :spinning="getQrcodeLoading">
					<div class="payCode flex justify-center align-center">
						<div class="qrcode flex justify-center align-center">
							<img v-if="qrcodeUrl" :src="resourcesHost + qrcodeUrl" />
							<div v-else-if="!qrcodeUrl && !getQrcodeLoading" class="getQrcodeFaild" @click="getPayQrcodeAgain">
								<div>支付二维码获取失败</div>
								<div>点击重新获取</div>
							</div>
							<div class="mask" v-if="!qrcodeUrl && !getQrcodeLoading"></div>
						</div>
					</div>
				</a-spin>
				<div class="text payAmount flex align-center justify-center">￥{{ payData.payAmount }}</div>
				<div class="text flex align-center justify-center">扫描二维码支付</div>
			</a-tab-pane>
			<a-tab-pane key="aliPay" tab="支付宝支付" class="payText" style="text-align: right; ">
				<a-spin :spinning="getQrcodeLoading">
					<div class="payCode flex justify-center align-center">
						<div class="qrcode flex justify-center align-center ">
							<img v-if="qrcodeUrl" :src="resourcesHost + qrcodeUrl" />
							<div v-else-if="!qrcodeUrl && !getQrcodeLoading" class="getQrcodeFaild" @click="getPayQrcodeAgain">
								<div>支付二维码获取失败</div>
								<div>点击刷新</div>
							</div>
							<div class="mask" v-if="!qrcodeUrl && !getQrcodeLoading"></div>
						</div>
					</div>
				</a-spin>
				<div class="text payAmount flex align-center justify-center">￥{{ payData.payAmount }}</div>
				<div class="text flex align-center justify-center">扫描二维码支付</div>
			</a-tab-pane>
		</a-tabs>
	</a-modal>
</template>

<script>
export default {
	props: ['payModalVisible', 'securityState', 'payData', 'tradeId', 'closePayModal', 'userType'],
	data() {
		return {
			currentPayType: 1,
			resourcesHost: null,
			qrcodeUrl: null,
			getQrcodeLoading: true,
			getTradeStatusInterval: null,
			getFlowIdStatusInterval: null,
			activeKey: 'wechatPay',
		};
	},
	watch: {
		payModalVisible(data) {
			if (data) {
				this.resourcesHost = process.env.VUE_APP_RESOURCES_HOST;
				this.getPayQrcode();
			}
		},
	},
	methods: {
		getPayQrcodeAgain() {
			this.getQrcodeLoading = true;
			this.qrcodeUrl = null;
			this.getPayQrcode();
		},
		changeTabsKey(key) {
			this.activeKey = key;
			this.getQrcodeLoading = true;
			this.qrcodeUrl = null;
			this.getPayQrcode();
		},
		closeModal() {
			this.getQrcodeLoading = true;
			this.qrcodeUrl = null;
			if (this.getTradeStatusInterval) {
				clearInterval(this.getTradeStatusInterval);
			}
			if (this.getFlowIdStatusInterval) {
				clearInterval(this.getFlowIdStatusInterval);
			}
			this.closePayModal();
		},
		getTradeStatus() {
			let params = {};
			params.tradeId = this.tradeId;
			const _this = this;
			this.$http
				.post('/monitortrade/allTradeList', params)
				.then(res => {
					let currentTradePayState = res.data.dataList[0].payState;
					if (currentTradePayState == 1) {
						_this.$message.success('支付成功');
						_this.closeModal();
					}
				})
				.catch(err => {
					console.log('获取订单状态失败', err);
				});
		},
		getFlowIdStatus() {
			const _this = this;
			this.$http
				.get('/securitycheckorder/query?flowId=' + this.tradeId)
				.then(res => {
					let currentTradePayState = '';
					if (_this.securityState && _this.securityState === 1) {
						currentTradePayState = res.data.balancePayState;
					} else {
						currentTradePayState = res.data.advancePayState;
					}
					if (currentTradePayState == 1) {
						_this.$message.success('支付成功');
						_this.closeModal();
					}
				})
				.catch(err => {
					console.log('获取订单状态失败', err);
				});
		},
		getPayQrcode() {
			const _this = this;
			let url = '/resource/pay/alipay';
			if (this.activeKey == 'aliPay') {
				url = '/resource/pay/alipay';
			} else {
				url = '/resource/pay/qrcode';
			}
			let params = {};
			if (_this.userType && this.userType === 6) {
				params.checkOrderId = this.tradeId;
			} else {
				params.tradeId = this.tradeId;
			}

			this.$http
				.post(url, params)
				.then(res => {
					_this.getQrcodeLoading = false;
					_this.qrcodeUrl = res.data.jsonHtml;
					if (_this.userType && _this.userType === 6) {
						if (_this.getFlowIdStatusInterval) {
							clearInterval(_this.getFlowIdStatusInterval);
						}
						_this.getFlowIdStatusInterval = setInterval(() => {
							_this.getFlowIdStatus();
						}, 1000);
					} else {
						if (_this.getTradeStatusInterval) {
							clearInterval(_this.getTradeStatusInterval);
						}
						_this.getTradeStatusInterval = setInterval(() => {
							_this.getTradeStatus();
						}, 1000);
					}
				})
				.catch(err => {
					this.qrcodeUrl = null;
					_this.getQrcodeLoading = false;
					console.log('获取支付二维码失败', err);
				});
		},
	},
};
</script>

<style lang="less" scoped>
.payModal {
	.payBox {
		padding-left: 10px;
		padding-right: 10px;
	}
	.text {
		font-size: 25px;
	}
	.payAmount {
		color: #fd9608;
		font-size: 38px;
	}
	.payCode {
		.qrcode {
			position: relative;
			width: 220px;
			height: 220px;
			background: url('https://resources.xbov.cn/img/hxznggfwpt/defaultQrcode.jpg') no-repeat;
			background-size: cover;
			.mask {
				position: absolute;
				width: 100%;
				height: 100%;
				background: rgba(22, 22, 22, 0.7);
			}
			/deep/.ant-spin-container {
				font-size: 0;
			}
			.getQrcodeFaild {
				background: #fff;
				padding: 8px 16px;
				border-radius: 50px;
				color: #000;
				z-index: 100;
				cursor: pointer;
				div {
					text-align: center;
				}
			}
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
	.label {
		text-align: right;
	}
	.ant-row {
		padding-bottom: 16px;
		font-size: 16px;
	}
}
</style>
