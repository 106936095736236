<template>
	<div class="pageContainer">
		<a-row class="CheckAdvertisingBox" :gutter="8">
			<a-spin :spinning="payLoading">
				<a-col class="CheckAdvertisingBox-left" :span="6">
					<div class="CheckAdvertisingBox-left-box">
						<div class="headTips">
							<p class="headTips-font">
								智广宝“安全检测”是专业的户外设施安全检测平台，平台拥有专业的户外设施安全检测机构，将严格按照住建部对于户外设施及户外广告门头牌匾风险排查整治的标准进行检测，并出具专业的检测报告。
							</p>
						</div>
						<div class="title">
							开始安全检测
							<img class="titleImg" src="../../assets/img/close.png" @click="showModal" />
						</div>

						<a-divider></a-divider>
						<div class="detectTypeBox">
							<div class="subTitle flex justify-between">
								<span>选择广告招牌类型</span>
							</div>
							<div class="detectTyBtnGroup btnGroup">
								<a-button @click="selectedDetectyType(item.type)" :type="currentDetectTyType == item.type ? 'primary' : ''" v-for="(item, index) in detectTyBtnList" :key="index">
									{{ item.name }}
								</a-button>
							</div>
						</div>
						<a-divider></a-divider>
						<a-form-model class="form" ref="Form" :model="form" :rules="rules">
							<div class="materialSelectedBox">
								<!-- <div class="specific">请输入具体数据</div> -->
								<div class="totalvalue">请输入宽高和面积</div>
								<div class="valueList">
									<a-form-model-item style="width: 94px; height: 32px; margin-right: 16px;" prop="width">
										<a-input-number v-model="form.width" :max-length="25" :min="0" style=" font-size: 14px;" placeholder="宽度（m）" />
									</a-form-model-item>
									<a-form-model-item style="width: 94px; height: 32px; margin-right: 16px;" prop="height">
										<a-input-number v-model="form.height" :min="0" :max-length="25" style=" font-size: 14px;" placeholder="高度（m）" />
									</a-form-model-item>
									<a-form-model-item style=" height: 32px; " prop="area">
										<a-input-number v-model="form.area" :min="0" :max-length="25" style="  width: 100px; font-size: 14px;" placeholder="总面积（m^2）" @change="changeArea" />
									</a-form-model-item>
								</div>
							</div>

							<div class="industrySelectedBox">
								请输入离地高度
							</div>
							<div style="margin-bottom: 25px">
								<a-form-model-item style="width: 104px; height: 32px; " prop="liftoffHeighr">
									<a-input-number v-model="form.liftoffHeighr" :min="0" :max-length="25" style=" font-size: 14px;" placeholder="高度（m）" />
								</a-form-model-item>
							</div>

							<div class="industrySelectedBox">
								请选择材料
							</div>
							<a-select allowClear style="width:178px; height: 32px; " placeholder="请选择材料" show-search v-model="materials" @change="handleChange" :filter-option="filterOption">
								<a-select-option v-for="item in materialList" :key="item.flowId" :value="item.materialsName">
									{{ item.materialsName }}
								</a-select-option>
							</a-select>
						</a-form-model>
						<a-divider></a-divider>
						<div class="addPhotoBox">
							<div class="phototext">请添加照片</div>
							<div class="photoBox">
								<a-upload
									class="photo"
									:action="resourcesHost"
									list-type="picture-card"
									:before-upload="beforeImgUpload"
									:file-list="imgFileList"
									@preview="handlePreview"
									@change="handleImgChange"
								>
									<div v-if="imgFileList.length < 1">
										<a-icon type="plus" />
										<div class="ant-upload-text">
											正面
										</div>
									</div>
								</a-upload>
								<a-upload
									class="photo"
									:action="resourcesHost"
									list-type="picture-card"
									:before-upload="afterImgUpload"
									:file-list="imgFileInfo"
									@preview="handlePreview"
									@change="ImgChange"
								>
									<div v-if="imgFileInfo.length < 1">
										<a-icon type="plus" />
										<div class="ant-upload-text">
											45°
										</div>
									</div>
								</a-upload>
							</div>
						</div>
					</div>
				</a-col>
				<a-col class="CheckAdvertisingBox-right" :span="18">
					<div class="CheckAdvertisingBox-right-box" style=" padding-left: 43px; ">
						<div style="font-size: 16px; color: rgb(112, 112, 112);  margin-top: 14px; margin-bottom: 25px;">
							<!-- <span>请输入个人信息</span> -->
						</div>
						<a-form-model ref="FormUser" :model="formUser" :rules="userRules">
							<div class="contactAddress">
								<div class="subTitleAddress">联系地址</div>
								<div class="inputFrame">
									<a-form-model-item style="margin-top: 10px;  height: 32px;" prop="address">
										<DictMapInput placeholder="请选择所在区域" v-model="currentAddress" @change="handleCascade"></DictMapInput>
									</a-form-model-item>
									<a-form-model-item style=" margin-top: -15px; width: 388px; height: 32px;" prop="detailsAddress">
										<a-input v-model="formUser.detailsAddress" style=" font-size: 14px; " placeholder="请输入详细地址" />
									</a-form-model-item>
								</div>
							</div>

							<div class="contactAddress">
								<div class="subTitleAddress">联系方式</div>
								<div class="formUserInput">
									<a-form-model-item style="margin-right: 16px; width: 139px; height: 32px;" prop="name">
										<a-input v-model="formUser.name" style=" font-size: 14px; text-align: left; " placeholder="请输入姓名" />
									</a-form-model-item>
									<a-form-model-item style=" width: 233px; height: 32px;" prop="telephone">
										<a-input v-model="formUser.telephone" style="  font-size: 14px; text-align: left; " placeholder="请输入电话号码" />
									</a-form-model-item>
								</div>
							</div>

							<a-divider></a-divider>

							<div>
								<div class="subTitleTime">预约检测</div>
								<div class="subTitleDate">
									<a-form-model-item prop="date">
										<a-date-picker v-model="formUser.date" format="YYYY-MM-DD" :disabled-date="disabledDate" @change="changeDate" placeholder="请选择检测日期" />
									</a-form-model-item>
									<div class="warning">
										<a-icon type="info-circle" />
										请选择工作日进行预约
									</div>
								</div>
							</div>
						</a-form-model>
						<div class="inspectorList">
							<div class="subTitleInspector">检测机构</div>
							<div class="inspectorperson">
								<div @click="inspectorIndex(index, item)" :class="item.count && item.count == 1 ? 'inspectored' : 'inspector'" v-for="(item, index) in inspectorInfo" :key="index">
									<img v-if="inspectorindex == index || (item.count && item.count == 0)" class="select" src="../../assets/img/select.png" />
									<div :class="inspectorindex == index ? ' inspectorCheck' : item.count && item.count == 1 ? 'inspectoreImged' : 'inspectorImg'">
										<img
											class="avatarImg"
											v-if="item.securityCheckCompanyPo && item.securityCheckCompanyPo.companyLogo"
											:src="item.securityCheckCompanyPo ? item.securityCheckCompanyPo.companyLogo : item.avatarUrl"
										/>
										<img class="avatarImg" v-else-if="item.avatarUrl" :src="item.avatarUrl" />
										<img class="avatarImg-list" v-else src="../../assets/img/headportrait.png" />
									</div>
									<div class="inspectorName">
										<a @click="queryUserInfo(item.userCode)">{{ item.name }}</a>
									</div>

									<div class="inspectortext">
										<p>{{ item.phone }}</p>
									</div>
								</div>
								<div style="margin-top: 60px; margin-left: 220px; font-size: 18px;" v-if="inspectorInfo.length === 0">
									暂无数据
								</div>
							</div>
						</div>
						<a-divider></a-divider>
						<div class="footBtn">
							<div style="margin-bottom: 14px">
								<div class="advanceCharge">预付款</div>
								<span class="price">测算面积为：{{ form.area || '0' }} 平方</span>
								<span class="price">
									需预付款：￥
									<span style="color: red;">{{ totalPayAmount ? totalPayAmount.toFixed(2) : '0' }}</span>
								</span>
							</div>
							<div class="prompt">
								*注：此款项为预付款。安全检测费用核算标准：以广告招牌类型、长宽比例、面积、离地高度、材料等因素综合计算，具体检测费用在检测机构接到订单之后，会和您电话联系确认检测总费用，如协商不成，可取消订单，预付款全额退还。
							</div>
							<a-button type="primary" size="small" class="submitBtn" @click="submit">提交检测</a-button>
						</div>
					</div>
				</a-col>
			</a-spin>
		</a-row>
		<a-modal :width="700" v-model="visible" :footer="false" centered :closable="false">
			<div class="modalHead">户外广告和招牌设施安全检查要点</div>
			<img class="closeImg" src="../../assets/img/close3x.png" @click="closeModal" alt="" srcset="" />
			<div class="modalMain">
				<p class="modalText">1.基础及地脚螺栓:基础无开裂、倾斜,钢筋及地脚螺栓无外露、松动、锈蚀,螺母无松动、锈蚀、缺失。</p>
				<p class="modalText">2.锚固螺栓及被依附体:被依附体结构无开裂、破损,锚固螺栓无外露、松动、锈蚀、缺失。</p>
				<p class="modalText">3.构架及连接:杆件平直,无变形、脱落;焊缝完好,无裂纹;连接螺栓完好,无缺失、松动、锈蚀。</p>
				<p class="modalText">4.面板及围护:面板及围护完好,无渗水、变形、翘裂、脱落、破损;扎绳管(杆)固定牢固,无脱落、锈蚀;画面材料完好,无破损、老化、褪色;显示单元固定完好,未松动。</p>
				<p class="modalText">5.构架防腐:涂层完好,无剥落、龟裂、风化;杆件无锈蚀。</p>
				<p class="modalText">
					6.电气及照明:电气控制系统。电器件动作灵敏、绝缘完好、触点无碳化,接电气控地可靠;电缆、电线绝缘完好,无老化;金属箱体及门扇接地(柱、桩)连接可靠;箱体固定可靠,无锈烂,防水防腐完好,门锁完好。照明系统。灯具完好齐全、固定无松动、接地可靠;灯杆固定牢固;导管(电缆桥架)及接线盒接地可靠、固定完好,无缺失、破损;电线与构架绝缘措施完好。霓虹灯装置。灯管完好,无老化、破损,固定规范,无松动、缺失;镇流器金属外壳接地可靠,高压输出线绝缘规范、可靠。
				</p>
				<p class="modalText">7.防雷设施:防雷装置完好,无损坏;接闪器、引下线焊接良好,无脱落、无锈蚀;金属部分等电位联接良好,无脱落、锈蚀,接地可靠;电涌保护器(SPD)运行状态指示正常。</p>
			</div>
		</a-modal>
		<Vviewer ref="viewer"></Vviewer>
		<PayModal :userType="userType" :payData="payData" :payModalVisible="payModalVisible" :tradeId="tradeId" :closePayModal="closePayModal" />
		<UserInfoDrawer :visible="showUserInfoDrawer" :userCode="queryUserInfoCode" :closeUserInfoDrawer="closeUserInfoDrawer" />
	</div>
</template>
<script src="https://unpkg.com/vue-amap/dist/index.js"></script>
<script>
import moment from 'moment';
import Vviewer from '../../components/Vviewer';
import PayModal from '../../components/PayModal';
import DictMapInput from '../../components/DictMapInput.vue';
import UserInfoDrawer from './components/UserInfoDrawer';
export default {
	components: {
		Vviewer,
		PayModal,
		DictMapInput,
		UserInfoDrawer,
	},
	data() {
		const checkFreeReviewTime = (rule, value, callback) => {
			if (value === '' || !value) {
				callback('请选择检测日期!');
			} else {
				callback();
			}
		};
		return {
			inspectorindex: undefined,
			inspectorInfo: [],
			currentAddress: '',
			visible: true,
			materials: undefined,
			checkUserCode: undefined,
			userType: 6,
			form: {
				width: '',
				height: '',
				area: '',
				liftoffHeighr: '',
			},
			rules: {
				width: [{ required: true, trigger: 'change', message: '请输入宽度', pattern: /^(([0-9]\d*))(\.\d{1,2})?$/ }],
				height: [{ required: true, trigger: 'change', message: '请输入高度', pattern: /^(([0-9]\d*))(\.\d{1,2})?$/ }],
				area: [{ required: true, trigger: 'change', message: '请输入面积', pattern: /^(([0-9]\d*))(\.\d{1,2})?$/ }],
				liftoffHeighr: [{ required: true, trigger: 'change', message: '请输入离地高度', pattern: /^(([0-9]\d*))(\.\d{1,2})?$/ }],
			},

			formUser: {
				address: '',
				province: '',
				city: '',
				district: '',
				detailsAddress: '',
				name: '',
				telephone: '',
				date: '',
			},
			userRules: {
				detailsAddress: [{ required: true, trigger: 'change', message: '请输入详情地址' }],
				name: [{ required: true, trigger: 'change', message: '请输入姓名', min: 2 }],
				telephone: [{ required: true, trigger: 'change', message: '请输入电话', pattern: /^1[3456789]\d{9}$/ }],
				date: [{ required: true, message: '请选择检测日期', validator: checkFreeReviewTime, trigger: 'change' }],
			},
			reviewUserCodeForm: {
				reviewUserCode: undefined,
			},
			currentDetectTyType: 0,
			payLoading: true,
			couponState: '',
			detectTyBtnList: [
				{
					type: 0,
					name: '门头广告牌',
				},
				{
					type: 1,
					name: '户外广告牌',
				},
			],
			materialBtnList: [
				{
					type: 1,
					name: '添加图片',
					check: true,
				},
			],
			ambientHost: process.env.NODE_ENV,
			fileBaseUrl: process.env.VUE_APP_RESOURCES_HOST,
			materialList: [],
			imgFileList: [],
			imgFileInfo: [],
			FileInfo: [],
			resourcesHost: '',
			content: '',
			payData: {},
			payModalVisible: false,
			totalPayAmount: 0, // 实付金额
			tradeId: null, // 主订单号
			imgUnitPrice: {},
			userInfo: {},
			dataList: [],
			province: '',
			formUserValid: false,
			formValid: false,
			queryUserInfoCode: null,
			showUserInfoDrawer: false,
		};
	},
	watch: {},
	created() {
		this.securitycheckmaterials();
		this.resourcesHost = process.env.VUE_APP_RESOURCES_API_HOST;
		this.userInfo = this.$store.getters.userInfo;
		this.queryUserbasicPageList();
	},
	methods: {
		showModal() {
			this.visible = true;
		},
		closeModal() {
			this.visible = false;
		},
		closeUserInfoDrawer() {
			this.showUserInfoDrawer = false;
			this.queryUserInfoCode = null;
		},
		queryUserInfo(userCode) {
			this.showUserInfoDrawer = true;
			this.queryUserInfoCode = userCode;
		},
		changeDate(e) {
			if (e) {
				const params = {
					userType: 6,
					appointmentTime: e._d,
				};
				if (this.province) {
					params.province = this.province;
				} else {
					params.province = this.userInfo.province;
				}
				this.payLoading = true;

				this.$http
					.post('/securitycheck/countCheck', params)
					.then(res => {
						this.inspectorInfo = res.data.dataList;
					})
					.catch(err => {
						console.log(err);
					})
					.finally(() => {
						this.payLoading = false;
					});
			}
		},
		changeArea(e) {
			const area = e;
			if (Number(e)) {
				this.queryGears(area);
			} else {
				this.queryGears(0);
			}
		},
		// 获取价格
		queryGears(data) {
			const _this = this;
			const params = {
				configKey: 'securityCheckGrade',
			};
			if (data && data != '') {
				params.area = data;
			} else {
				params.area = 0;
			}
			_this.$http
				.post('/securitycheck/queryGears', params)
				.then(res => {
					_this.totalPayAmount = res.data.gradeAmount;
				})
				.catch(err => {
					console.log(err);
				});
		},
		// 获取材料
		securitycheckmaterials() {
			const _this = this;
			_this.$http
				.post('/securitycheckmaterials/queryList', {})
				.then(res => {
					_this.materialList = res.data.dataList;
				})
				.catch(err => {
					console.log(err);
				})
				.finally(() => {
					this.payLoading = false;
				});
		},
		handleChange(value) {
			this.materials = value;
		},
		// 地图选择
		handleCascade(val) {
			const {
				address,
				location: [longitude, latitude],
				regions: [province, city, district],
			} = val;
			this.$set(this.formUser, 'address', address + province + city + district);
			this.province = province;
			this.queryUserbasicPageList();
			// this.$set(this.form, 'longitude', longitude);
			// this.$set(this.form, 'latitude', latitude);
			this.$set(this.formUser, 'province', province);
			this.$set(this.formUser, 'city', city);
			this.$set(this.formUser, 'district', district);
			// this.$refs.form.validateField(['province', 'address'])
		},
		disabledDate(current) {
			// Can not select days before today and today
			return current && current < moment().endOf('day');
		},
		beforeImgUpload(file) {
			return new Promise((resolve, reject) => {
				var testmsg = /^image\/(jpeg|png|jpg|bmp|gif)$/.test(file.type);
				const isLt20M = file.size / 1024 / 1024 <= 20; //图片大小不超过2MB
				if (!testmsg) {
					this.$message.error('仅支持图片!');
					return reject(false);
				}
				if (!isLt20M) {
					this.$message.error('上传图片大小不能超过20M!');
					return reject(false);
				}
				return resolve(true);
			});
		},
		afterImgUpload(file) {
			return new Promise((resolve, reject) => {
				var testmsg = /^image\/(jpeg|png|jpg|bmp|gif)$/.test(file.type);
				const isLt20M = file.size / 1024 / 1024 <= 20; //图片大小不超过2MB
				if (!testmsg) {
					this.$message.error('仅支持图片!');
					return reject(false);
				}
				if (!isLt20M) {
					this.$message.error('上传图片大小不能超过20M!');
					return reject(false);
				}
				return resolve(true);
			});
		},
		closePayModal() {
			this.payModalVisible = false;
			this.$router.push({ name: 'SecurityOrder' });
		},
		//查询检查员列表
		queryUserbasicPageList() {
			const _this = this;
			let params = {
				userType: 6,
			};
			if (this.province) {
				params.province = this.province;
			} else {
				params.province = this.userInfo.province;
			}

			this.$http
				.post('/userbasic/queryPageList', params)
				.then(res => {
					this.inspectorInfo = res.data.dataList;
				})
				.catch(err => {
					console.log('查询检测机构失败：', err);
				})
				.finally(() => {
					_this.payLoading = false;
				});
		},
		//点击选择检查员
		inspectorIndex(index, data) {
			if (data.count && data.count === 1) {
				this.$message.warning('该检测机构已被预约！');
				return;
			} else {
				this.inspectorindex = index;
				this.checkUserCode = data.userCode;
			}
		},

		submit() {
			const _this = this;
			const params = {
				userCode: this.userInfo.userCode,
				type: this.currentDetectTyType,
			};
			if (_this.form.height == 0 || _this.form.width == 0 || _this.form.area == 0 || _this.form.liftoffHeighr == 0) {
				this.$message.warning('数值不能为零!');
				return;
			}
			this.$refs.Form.validate(valid => {
				this.formValid = valid;
				if (valid) {
					params.extent = _this.form.height;
					params.width = _this.form.width;
					params.area = _this.form.area;
					params.height = _this.form.liftoffHeighr;
				}
			});
			if (!_this.formValid) {
				this.$message.warning('请输入具体数据!');
				return;
			}
			if (_this.materials) {
				params.materials = _this.materials;
			} else {
				this.$message.warning('请选择材料!');
				return;
			}
			if (_this.imgFileList.length > 0) {
				params.photoFrontUrl = this.fileBaseUrl + _this.imgFileList[0].response.data.filePath;
			} else {
				this.$message.warning('请上传正面照!');
				return;
			}
			if (_this.imgFileInfo.length > 0) {
				params.photoDegreesUrl = this.fileBaseUrl + _this.imgFileInfo[0].response.data.filePath;
			} else {
				this.$message.warning('请上传45°传照!');
				return;
			}
			if (_this.formUser.address == '') {
				this.$message.warning('请选择所在区域!');
				return;
			}
			this.$refs.FormUser.validate(valid => {
				this.formUserValid = valid;
				if (valid) {
					params.location = _this.formUser.address;
					params.province = _this.formUser.province;
					params.city = _this.formUser.city;
					params.district = _this.formUser.district;
					params.detailedAddress = _this.formUser.detailsAddress;
					params.contactsName = _this.formUser.name;
					params.contactsPhone = _this.formUser.telephone;
					params.appointmentTime = _this.formUser.date;
				}
			});
			if (!_this.formUserValid) {
				this.$message.warning('请输入个人信息!');
				return;
			}
			if (this.checkUserCode) {
				params.checkUserCode = _this.checkUserCode;
			} else {
				this.$message.warning('请选择测试员!');
				return;
			}
			_this.payLoading = true;
			_this.$http
				.post('/securitycheck/add', params)
				.then(res => {
					if (res.rescode == 200) {
						_this.payData.payAmount = res.data.advanceAmount;
						_this.tradeId = res.data.checkOrderId;
						_this.payModalVisible = true;
					} else if (res.rescode == 201) {
						this.$message.warning(res.msg);
					}
				})
				.catch(err => {
					console.log('err', err);
				})
				.finally(() => {
					_this.payLoading = false;
				});
		},
		async handlePreview(file) {
			if (file.type.indexOf('image') != -1) {
				this.$refs.viewer.show([
					{
						thumbnail: process.env.VUE_APP_RESOURCES_HOST + file.response.data.filePath,
						source: process.env.VUE_APP_RESOURCES_HOST + file.response.data.filePath,
					},
				]);
			}
		},
		async handleImgChange({ file, fileList }) {
			if (file.status == 'done' && file.response.rescode == 200) {
				fileList.map(item => {
					if (item.status == 'done') {
						if (item.response.data.coverImgUrl && item.response.data.coverImgUrl != '') {
							item.thumbUrl = process.env.VUE_APP_RESOURCES_HOST + item.response.data.coverImgUrl;
						}
						this.FileInfo = item.response.data;
					}
				});
			} else if (file.status == 'done' && file.response.rescode == 201) {
				this.$message.warning(file.response.msg);
				fileList = fileList.filter(item => {
					return item.uid != file.uid;
				});
			}
			this.imgFileList = fileList;
		},
		async ImgChange({ file, fileList }) {
			if (file.status == 'done' && file.response.rescode == 200) {
				fileList.map(item => {
					if (item.status == 'done') {
						if (item.response.data.coverImgUrl && item.response.data.coverImgUrl != '') {
							item.thumbUrl = process.env.VUE_APP_RESOURCES_HOST + item.response.data.coverImgUrl;
						}
						this.FileInfo = item.response.data;
					}
				});
			} else if (file.status == 'done' && file.response.rescode == 201) {
				this.$message.warning(file.response.msg);
				fileList = fileList.filter(item => {
					return item.uid != file.uid;
				});
			}
			this.imgFileInfo = fileList;
		},

		selectedDetectyType(type) {
			this.currentDetectTyType = type;
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
		},
	},
};
</script>

<style lang="less" scoped>
.pageContainer {
	display: flex;
	flex-direction: column;

	.CheckAdvertisingBox {
		height: 100%;
		.ant-spin-nested-loading {
			height: 100%;
			/deep/.ant-spin-container {
				height: 100%;
			}
		}

		.headTips {
			background-color: #e7f3fd;
			box-sizing: border-box;
			margin: 0 12px;
			padding-bottom: 7px;
			margin-bottom: 15px;
			.headTips-font {
				font-size: 14px;
				color: rgb(153, 153, 153);
				padding: 11px 24.5px 6px;
				line-height: 20px;
				word-spacing: normal;
			}
		}

		.subTitle {
			font-size: 16px;
			margin-top: 17.5px;
			color: rgb(112, 112, 112);
			line-height: 22px;
			margin-bottom: 20px;
		}
		.prompt {
			color: #f57474;
			font-size: 14px;
			width: 374px;
			height: 51px;
			line-height: 20px;
			margin-bottom: 17px;
		}
		.ant-col {
			height: 100%;
		}

		&-left-box {
			height: 100%;
			background: #fff;
			.btnGroup {
				display: flex;
				justify-content: start;
				margin-bottom: 14px;
				.ant-btn {
					width: 120px;
					margin-right: 22px;
				}
			}

			.form {
				margin-left: 28px;
				margin-top: 16px;
				margin-bottom: 24px;
				.materialSelectedBox {
					.specific {
						font-size: 16px;
						color: rgb(112, 112, 112);
					}
					.totalvalue {
						color: rgb(51, 51, 51);
						font-size: 16px;
						margin-top: 10px;
						margin-bottom: 10px;
					}
					.valueList {
						display: flex;
						justify-content: start;
						margin-bottom: 25px;
						height: 32px;
					}
				}
				.industrySelectedBox {
					margin-top: 20px;
					font-size: 16px;
					color: rgb(51, 51, 51);
					margin-bottom: 10px;
				}
			}
			.addPhotoBox {
				margin-top: 20px;
				margin-left: 28px;
				.phototext {
					font-size: 16px;
					color: rgb(51, 51, 51);
					margin-bottom: 16px;
				}
				.photoBox {
					display: flex;
					justify-content: start;
					.photo {
						display: block;
						width: 69px;
						height: 69px;
						margin-right: 68px;
					}
				}
			}

			.detectTypeBox {
				margin-left: 24px;
				.prompt {
					padding: 0 16px 16px;
				}
			}
			.title {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 18px;
				margin-bottom: 19.5px;
				color: #178bf1;
				.titleImg {
					width: 18px;
					height: 18px;
					margin-left: 7.5px;
					cursor: pointer;
				}
			}
		}

		&-right-box {
			height: 100%;
			background: #fff;
			overflow-y: auto;
			.contactAddress {
				.subTitleAddress {
					margin-bottom: 7px;
					font-size: 16px;
					color: rgb(51, 51, 51);
				}
				.inputFrame {
					margin-bottom: 25px;
				}
				.formUserInput {
					display: flex;
					height: 60px;
				}
			}
			.subTitleTime {
				margin-top: 20px;
				margin-bottom: 7px;
				font-size: 16px;
				color: rgb(51, 51, 51);
			}
			.subTitleDate {
				display: flex;
				.warning {
					margin-left: 10px;
					margin-top: 9px;
					color: orange;
				}
			}
			.inspectorList {
				height: 200px;

				.subTitleInspector {
					font-size: 16px;
					color: rgb(51, 51, 51);
				}
				.inspectorperson {
					display: flex;
					overflow-x: scroll;
					height: 200px;
				}
				/* 修改滚动条样式 */
				.inspectorperson::-webkit-scrollbar {
					/*
				    width height分别对应竖向滚动条和横向滚动条
				*/
					width: 15px;
					height: 10px;
					background-color: #ffffff;
				}

				/* 修改滚动条中的小滑块 */
				.inspectorperson::-webkit-scrollbar-thumb {
					width: 10px;
					border-radius: 10px;
					background-color: #ccc;
				}

				/* 定义滚动条的轨道 */
				.inspectorperson::-webkit-scrollbar-track {
					background-color: #fff;
				}
				// /* 定义轨道两端的按钮 */
				// .inspectorperson::-webkit-scrollbar-button {
				// 	// background-color: green;
				// }
				.inspectored {
					opacity: 0.5;
					margin-top: 27px;
					margin-right: 35px;
					width: 6em;
					position: relative;
					.select {
						width: 20px;
						height: 20px;
						position: absolute;
						top: -4px;
						right: 0;
						z-index: 999;
					}
					.inspectoreImged {
						width: 100%;
						height: 6em;
						border-radius: 50%;
						margin-bottom: 12px;
						.avatarImg {
							border-radius: 50%;
							border: none;
							width: 100%;
							height: 100%;
						}
					}
					.inspectorName {
						font-size: 16px;
						color: rgb(51, 51, 51);
						text-align: center;
					}
					.inspectortext {
						margin-top: 10px;
						font-size: 12px;
						color: rgb(153, 153, 153);
						text-align: center;
					}
				}
				.inspector {
					margin-top: 27px;
					margin-right: 35px;
					width: 6em;
					cursor: pointer;
					position: relative;
					.select {
						width: 20px;
						height: 20px;
						position: absolute;
						top: -4px;
						right: 0;
						z-index: 999;
					}
					.inspectorCheck {
						border: 2px solid rgb(42, 130, 228);
						border-radius: 50%;
						width: 100%;
						height: 6em;
						margin-bottom: 12px;
						.avatarImg {
							border-radius: 50%;
							width: 100%;
							height: 100%;
						}
						.avatarImg-list {
							margin-top: 5px;
							width: 50%;
							height: 80%;
							margin-left: 17px;
						}
					}
					.inspectorImg {
						border-radius: 50%;
						border: 2px solid rgb(153, 153, 153);
						width: 100%;
						height: 6em;
						margin-bottom: 12px;
						.avatarImg {
							border-radius: 50%;
							border: none;
							width: 100%;
							height: 100%;
						}
						.avatarImg-list {
							margin-top: 5px;
							width: 50%;
							height: 80%;
							margin-left: 17px;
						}
					}

					.inspectorName {
						font-size: 16px;
						color: rgb(51, 51, 51);
						text-align: center;
					}
					.inspectortext {
						margin-top: 10px;
						font-size: 12px;
						color: rgb(153, 153, 153);
						text-align: center;
					}
				}
			}
			.footBtn {
				margin-top: 23.5px;
				position: relative;
				.advanceCharge {
					font-size: 16px;
					color: rgb(51, 51, 51);
					margin-bottom: 20px;
				}
				.price {
					font-size: 20px;
					color: rgb(51, 51, 51);
					padding-right: 37px;
				}
				.submitBtn {
					position: absolute;
					left: 450px;
					top: 38px;
					width: 122px;
					height: 36px;
				}
			}
		}
	}
}
.modalHead {
	height: 50px;
	width: 100%;
	border-bottom: 1px solid #ccc;
	font-size: 20px;
	font-weight: 600;
	color: #000;
	text-align: center;
}
.modalMain {
	margin-top: 20px;
	line-height: 25px;
	.modalText {
		padding: 7px 0;
	}
}
.closeImg {
	position: absolute;
	cursor: pointer;
	top: 20px;
	right: 20px;
	width: 25px;
	height: 25px;
}
</style>
