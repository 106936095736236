<template>
	<a-drawer width="1100" title="详情信息" placement="right" :maskClosable="false" :visible="visible" @close="onClose">
		<a-spin :spinning="pageLoading" tip="加载中···">
			<a-divider orientation="left">检测机构信息</a-divider>
			<a-descriptions>
				<a-descriptions-item label="公司名称">{{ checkCompanyList.companyName || '--' }}</a-descriptions-item>
				<a-descriptions-item label="联系电话">{{ userInfo.phone || '--' }}</a-descriptions-item>
				<a-descriptions-item label="注册时间">{{ userInfo.addTime ? moment(userInfo.addTime) : '--' }}</a-descriptions-item>
				<a-descriptions-item label="账号类型">
					<a-tag color="#52c41a" v-if="userInfo.userType && userInfo.userType == 6 && checkCompanyList.flowId">检测机构</a-tag>
					<span v-else>--</span>
				</a-descriptions-item>
				<a-descriptions-item label="所在区域">
					{{ userInfo.province ? userInfo.province + (userInfo.city || '') + (userInfo.district || '') : '--' }}
				</a-descriptions-item>
				<a-descriptions-item label="详细地址">{{ userInfo.address || '--' }}</a-descriptions-item>
				<a-descriptions-item label="营业执照号码">{{ checkCompanyList.licenseNumber || '--' }}</a-descriptions-item>
				<a-descriptions-item label="资质文件">
					<a v-if="checkCompanyList.checkAptitudes" :href="checkCompanyList.checkAptitudes" target="_blank">查看原件</a>
					<span v-else>--</span>
				</a-descriptions-item>
			</a-descriptions>
			<a-descriptions>
				<a-descriptions-item contentStyle class="business">
					<div style="color:#000;">营业执照照片:</div>
					<div>
						<img
							v-if="checkCompanyList.businessLicense"
							:src="checkCompanyList.businessLicense"
							@click="preview(checkCompanyList.businessLicense)"
							style="width:180px;height:150px; margin-top:10px;"
						/>
					</div>
				</a-descriptions-item>
			</a-descriptions>
		</a-spin>
		<Vviewer ref="viewer"></Vviewer>
	</a-drawer>
</template>
<script>
import moment from 'moment';
import Vviewer from '../../../components/Vviewer';
export default {
	props: ['visible', 'userCode', 'closeUserInfoDrawer'],
	components: {
		Vviewer,
	},
	data() {
		const _this = this;
		return {
			now: Date.now(),
			tableHeight: 280,
			userInfo: {},
			pageLoading: false,
			currentTimeStamp: this.$moment().format('x'),
			checkCompanyList: [],
			tmporaryMember: null,

			pagination: {
				total: 0,
				current: 0,
				defaultPageSize: 10,
				showTotal: (total, range) => `当前第 ${range[0]}-${range[1]} 条 共计 ${total} 条`,
				showSizeChanger: true,
				pageSizeOptions: ['5', '10', '15', '20'],
				onShowSizeChange: (current, pageSize) => {
					this.pageSize = pageSize;
				},
			},
			compTime: null,
		};
	},
	watch: {
		visible(data) {
			if (!data) {
				this.userInfo = {};
			} else {
				this.queryUserInfo();
			}
		},
	},
	methods: {
		tableChange(e) {
			this.pagination.current = e.current;
		},
		moment(timeStap) {
			return this.$moment(timeStap).format('YYYY-MM-DD HH:mm:ss');
		},
		preview(data) {
			this.$refs.viewer.show([
				{
					thumbnail: data,
					source: data,
				},
			]);
		},
		queryUserInfo() {
			let _this = this;
			_this.pageLoading = true;
			this.$http
				.get('/userbasic/query?userCode=' + _this.userCode)
				.then(res => {
					_this.pageLoading = false;
					if (res.rescode == 200) {
						_this.userInfo = res.data;
						_this.checkCompanyList = res.data.securityCheckCompanyPo || [];
						_this.vipTime = _this.$moment(res.data.freeReviewTime).format('YYYY-MM-DD HH:mm:ss');
					} else {
						_this.$message.error('查询用户失败');
					}
				})
				.catch(err => {
					_this.pageLoading = false;
					_this.$message.error('查询用户失败');
				});
		},
		onClose() {
			this.closeUserInfoDrawer();
		},
	},
};
</script>

<style lang="less" scoped>
.business {
	display: flex;
	flex-direction: row;
}
</style>
